/* NotoSansKR 폰트 로드
@font-face {
  font-family: 'NotoSansKR';
  src: url('../../public/fonts/NotoSansKR-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
} */

/* NotoSansKR */
@font-face {
  font-family: 'NotoSansKR';
  font-style: thin;
  font-weight: 100;
  src: url('../../public/fonts/NotoSansKR-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: extraLight;
  font-weight: 200;
  src: url('../../public/fonts/NotoSansKR-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: light;
  font-weight: 300;
  src: url('../../public/fonts/NotoSansKR-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: regular;
  font-weight: 400;
  src: url('../../public/fonts/NotoSansKR-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: medium;
  font-weight: 500;
  src: url('../../public/fonts/NotoSansKR-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: semiBold;
  font-weight: 600;
  src: url('../../public/fonts/NotoSansKR-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: bold;
  font-weight: 700;
  src: url('../../public/fonts/NotoSansKR-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: extraBold;
  font-weight: 800;
  src: url('../../public/fonts/NotoSansKR-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: black;
  font-weight: 900;
  src: url('../../public/fonts/NotoSansKR-Black.ttf') format('truetype');
}
